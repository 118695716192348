import React from "react"
import { Col, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { Layout } from "../components"

export default function Welcome() {
  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "main",
        }}
      />
      <Row>
        <Col xs="12">
          <div className="content-container welcome-image">
            <div className="welcome-info info">
              <div className="logo">
                <img src={require("../../static/logo.webp").default}></img>
              </div>
              <div className="site-header">
                <h2>Radca Prawny</h2>
              </div>
              <hr className="line"></hr>
              <div className="name">
                <h1>Radosław Rybak</h1>
              </div>
              <div className="cta">
                <h3>Zadzwoń</h3>
                <h4>(+48) 606 478 985</h4>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}
